import React, {useCallback, useEffect, useRef, useState} from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import Icon from "./icon"
import Button from "./buttons"
import htmlElementOffset from "../utils/htmlElementOffset"

import "./detailsInfoFloater.css"
import {useOutsideClickHandler} from "../utils/outsideClickHandler";

const Toggle = styled.div`
  display: none;
  position: fixed;
  top: 30%;
  right: ${props => (props.open ? "75%" : "0")};
  height: 40px;
  width: 40px;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  background-color: black;
  color: white;
  transition: all 0.3s ease-in;

  @media (max-width: 768px) {
    display: flex;
  }
`

const Floater = styled.div`
  position: ${props => (props.floaterAbsolutePosition ? 'absolute' : '')};
  top: ${props => (props.floaterAbsolutePosition ? `${props.floaterAbsolutePosition}px` : '')};
  transform: ${props => (props.floaterAbsolutePosition ? 'translate(-50%)' : '')};

  @media (max-width: 768px) {
    position: fixed;
    width: 75%;
    transition: all 0.3s ease-in;
    right: ${props => (props.open ? "0" : "-100%")};
    left: auto;
    top: auto;
    transform: none;
    font-size: 10px;
  }
`

const DetailsInfoFloater = ({ type, shadow, title, duration, price, unit , details}) => {
  const [floaterOpen, setFloaterOpen] = useState(false)
  const [floaterAbsolutePosition, setAbsolutePosition] = useState(null)
  const handleScroll = useCallback(() => {
    const footer = document.getElementById("footer")
    if (footer) {
      const footerTop = footer.getBoundingClientRect().top
      if (window.innerHeight > footerTop) {
        if (!floaterAbsolutePosition){
          const floater = document.getElementById("floater")
          setAbsolutePosition(htmlElementOffset(floater).top)
        }
      } else {
        if (floaterAbsolutePosition) setAbsolutePosition(null)
      }
    }
  }, [setAbsolutePosition, floaterAbsolutePosition])
  const wrapperRef = useRef(null);
  useOutsideClickHandler(wrapperRef, () => setFloaterOpen(false));

  useEffect(() => {
    window.addEventListener(`scroll`, handleScroll)
    return () => window.removeEventListener(`scroll`, handleScroll)
  }, [handleScroll])

  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: {eq: "home/baseInfo.json"}) {
          childHomeJson {
            telephone_pl
            telephone_en
          }
        }
      }
    `
  )
  const baseInfo = data.file.childHomeJson
  let floaterContent
  switch (type){
    case "procedure":
      floaterContent = {
        header: title.toUpperCase(),
        priceInfo: <React.Fragment>Cena od<span>{price} {unit}</span></React.Fragment>,
        subPriceInfo: <mark>{details.price_description}</mark>,
        contentFirstHeader: details.specialist,
        contentFirstDescription: details.specialist_expirience,
        contentSecondHeader: 'Czas trwania',
        contentSecondDescription: duration,
        contentThirdHeader: 'Efekt',
        contentThirdDescription: details.effect,
        contentFourthHeader: 'Znieczulenie',
        contentFourthDescription: details.anesthesia,
        contentFifthHeader: 'Lokalizacja',
        contentFifthDescription: details.localization,
        contentSixthHeader: 'Wylot',
        contentSixthDescription: details.travel,
        contentSeventhHeader: 'Polskojezyczny zespół'
      };
      break;
    case "training":
      floaterContent = {
        header: 'SZKOLENIE',
        priceInfo: <React.Fragment>Szkolenie <span>{price} {unit}</span></React.Fragment>,
        subPriceInfo: null,
        contentFirstHeader: 'Wykfalifikowany szkoleniowiec',
        contentFirstDescription: 'Wieloletnia praktyka',
        contentSecondHeader: 'Czas trwania',
        contentSecondDescription: <React.Fragment>Szkolenie trwa <strong>1 dzień</strong></React.Fragment>,
        contentThirdHeader: 'Informacja',
        contentThirdDescription: <React.Fragment>Zatwierdzone umiejętności <strong>potwierdzamy certyfikatem</strong> upoważniającym do wykonywania zawodu stylisty przedłużania rzęs.</React.Fragment>
      }
      break;
    default:
  }
  return (
    <>
      <Toggle open={floaterOpen} onClick={() => setFloaterOpen(!floaterOpen)}><Icon icon='info'/></Toggle>
      <Floater id='floater' className='floater-body' ref={wrapperRef} floaterAbsolutePosition={floaterAbsolutePosition} open={floaterOpen}>
        <div className='floater-header'>{floaterContent.header}</div>
        <div className='floater-container'>
          <Image
            fluid={shadow}
            style={{ position: "absolute", width: "100%", height: "20px", bottom: "-24px", left: "", zIndex: "-1" }}
            loading="eager"
            imgStyle={{ objectFit: "100% 100%" }}
          />
          <div className='floater-content'>
            <div className='floater-content-first-section'>
              <div>{floaterContent.priceInfo}</div>
              <div>{floaterContent.subPriceInfo}</div>
            </div>
            {(floaterContent.contentFirstHeader || floaterContent.contentFirstDescription) && <div className='floater-content-info-section'>
              <Icon icon='aid-kit' className='floater-icon'/>
              <div>
                <div>{floaterContent.contentFirstHeader}</div>
                <div>{floaterContent.contentFirstDescription}</div>
              </div>
            </div>}
            {floaterContent.contentSecondDescription && <div className='floater-content-info-section'>
              <Icon icon='clock' className='floater-icon'/>
              <div>
                <div>{floaterContent.contentSecondHeader}</div>
                <div>{floaterContent.contentSecondDescription}</div>
              </div>
            </div>}
            {floaterContent.contentThirdDescription && <div className='floater-content-info-section'>
              <Icon icon='info' className='floater-icon'/>
              <div>
                <div>{floaterContent.contentThirdHeader}</div>
                <div>{floaterContent.contentThirdDescription}</div>
              </div>
            </div>}
            {floaterContent.contentFourthDescription && <div className='floater-content-info-section'>
              <Icon icon='aid-kit' className='floater-icon'/>
              <div>
                <div>{floaterContent.contentFourthHeader}</div>
                <div>{floaterContent.contentFourthDescription}</div>
              </div>
            </div>}
            {floaterContent.contentFifthDescription && <div className='floater-content-info-section'>
              <Icon icon='location' className='floater-icon'/>
              <div>
                <div>{floaterContent.contentFifthHeader}</div>
                <div>{floaterContent.contentFifthDescription}</div>
              </div>
            </div>}
            {floaterContent.contentSixthDescription && <div className='floater-content-info-section'>
              <Icon icon='airplane' className='floater-icon'/>
              <div>
                <div>{floaterContent.contentSixthHeader}</div>
                <div>{floaterContent.contentSixthDescription}</div>
              </div>
            </div>}
            {(floaterContent.contentSeventhHeader || floaterContent.contentSeventhDescription) && <div className='floater-content-info-section'>
              <Icon icon='info' className='floater-icon'/>
              <div>
                <div>{floaterContent.contentSeventhHeader}</div>
                <div>{floaterContent.contentSeventhDescription}</div>
              </div>
            </div>}
            <Button as='a' type='black' className='floater-button' name='whatsapp' aria-label='whatsapp' target="_blank noreferrer" href={`https://wa.me/${baseInfo.telephone_pl}`}><Icon icon='whatsapp' className='floater-icon'/><div>Umów konsultację</div></Button>
            <Button as='a' type='white' className='floater-button' name='phone' aria-label='phone' href={`tel:${baseInfo.telephone_pl}`}><Icon icon='phone' className='floater-icon'/>Zadzwoń</Button>
          </div>
        </div>
      </Floater>
    </>
  )
}

export default DetailsInfoFloater

import React from "react"
import Layout from "../components/layout"
import Image from "gatsby-image"
import { graphql } from "gatsby"
import DetailsInfoFloater from "../components/detailsInfoFloater"
import CustomGallery, {CustomVideo} from "../components/gallery"

import "../pages/extesibleBannersCommon.css"
import "./styles.css"

export default function Procedure({ pageContext, data }) {
  const bannerShadow = data.bannerShadow.childImageSharp.fluid
  const images = pageContext.details.gallery.reduce((acc, node) => node ? acc.concat(node.childImageSharp) : acc, [])
  return (
    <Layout>
      <DetailsInfoFloater type="procedure" shadow={bannerShadow} title={pageContext.title} duration={pageContext.duration_first_time}
                          price={pageContext.price_first_time} unit={pageContext.unit} details={pageContext.floater_details}/>
      <div className="background-image">
        <Image
          fluid={pageContext.banner.childImageSharp.fluid}
          className="background-image-medium banner-header-background"
        />
        <h1 className="banner-header-text">{pageContext.title}</h1>
        <Image
          fluid={bannerShadow}
          className="background-rounded-shadow"
          style={{ position: "absolute" }}
          loading="eager"
        />
      </div>
      <div className="content-container details-container">
        <div className="text-section">
          {pageContext.details.content.map((element, index) => {
            return (
              <div key={index} className='paragraph'>
                {element.header ? (
                    <h2 dangerouslySetInnerHTML={{ __html: element.header }}/>
                ) : (
                    <h4 dangerouslySetInnerHTML={{ __html: element.header2 }}/>
                )}

                <div className='paragraph' dangerouslySetInnerHTML={{ __html: element.description }}/>
              </div>
            )
          })}
        </div>
        <div className="text-section">
          <h3>Galeria:</h3>
          <div className='paragraph'>
            <CustomGallery images={images}/>
          </div>
          {pageContext.details.videos && pageContext.details.videos.map((video, index) => {
            return (
              <div key={index} className='paragraph'>
                <CustomVideo className='custom-video' width="100%" poster={video.poster} controls>
                  <source src={video.src} type="video/mp4"/>
                  Twoja przeglądarka nie mogła wyświetlić tego filmu
                </CustomVideo>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    bannerShadow: file(relativePath: {eq: "images/banner_header_shadow.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

